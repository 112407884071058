var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sticky-top",staticStyle:{"z-index":"12"}},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-dark shadow-sm bg-light",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"container p-2"},[_c('router-link',{staticClass:"navbar-brand d-flex text--white align-items-center",attrs:{"to":"/home"}},[_c('img',{staticClass:"d-lg-block d-md-block",attrs:{"src":require("@/assets/images/SVG/icon.svg"),"height":"48","alt":""}}),_c('span',{staticClass:"ml-3 font-weight-bold font-main logotext"},[_vm._v(_vm._s(_vm.$t("app_name")))])]),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbar4"}},[_c('ul',{staticClass:"navbar-nav ml-auto pl-lg-4"},[_c('li',{staticClass:"nav-item px-lg-2"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/home"}},[_c('span',{staticClass:"d-inline-block mr-2 icon-width"},[_c('i',{staticClass:"fas fa-home"})]),_vm._v(_vm._s(_vm.$t("navbar.home")))])],1),_c('li',{staticClass:"nav-item px-lg-2"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/orders"}},[_c('span',{staticClass:"d-inline-block mr-2 icon-width"},[_c('i',{staticClass:"fas fa-spa"})]),_vm._v(_vm._s(_vm.$t("navbar.orders")))])],1),_c('li',{staticClass:"nav-item dropdown px-lg-2",class:_vm.$route.path == '/profile'
                ? 'active'
                : '' || _vm.$route.path == '/change-password'
                ? 'active'
                : '' || _vm.$route.path == '/stats'
                ? 'active'
                : ''},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","id":"navbarDropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"fas mr-2 fa-user-alt"}),_vm._v(" "+_vm._s(_vm.$t("navbar.profile"))+" ")]),_c('div',{staticClass:"dropdown-menu",staticStyle:{"z-index":"100"},attrs:{"aria-labelledby":"navbarDropdownMenuLink"}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/profile"}},[_vm._v(_vm._s(_vm.$t("navbar.my_profile")))]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/change-password"}},[_vm._v(_vm._s(_vm.$t("navbar.change_password")))]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/stats"}},[_vm._v(_vm._s(_vm.$t("navbar.stats")))])],1)]),_c('li',{staticClass:"nav-item px-lg-2"},[_c('a',{staticClass:"nav-link",on:{"click":function($event){$event.preventDefault();return _vm.logOut.apply(null, arguments)}}},[_vm._m(1),_vm._v(_vm._s(_vm.$t("navbar.logout")))])])])])],1)]),(
      !_vm.orderedForTomorrow &&
      _vm.$route.path !== '/change-password' &&
      _vm.$route.path !== '/profile' &&
      _vm.$route.path !== '/orders' &&
      _vm.$route.name !== 'product' &&
      _vm.$route.path !== '/stats'
    )?_c('v-banner',{staticClass:"backdrop",attrs:{"icon":"mdi-alert-circle","icon-color":"white darken-4","single-line":""}},[_vm._v(" "+_vm._s(_vm.$t("navbar.havent_ordered"))+" ")]):_vm._e(),(
      _vm.orderedForTomorrow &&
      _vm.$route.path !== '/change-password' &&
      _vm.$route.path !== '/profile' &&
      _vm.$route.path !== '/orders' &&
      _vm.$route.name !== 'product' &&
      _vm.$route.path !== '/stats'
    )?_c('v-banner',{staticClass:"backdrop2 bottomtext",attrs:{"single-line":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"actions",fn:function({ dismiss }){return [_c('v-btn',{attrs:{"text":"","color":"#ffcd9a"},on:{"click":dismiss}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1925643052),model:{value:(_vm.v0),callback:function ($$v) {_vm.v0=$$v},expression:"v0"}},[_vm._v(" "+_vm._s(_vm.$t("navbar.already_ordered"))+" "),(_vm.count > 2)?_c('span',[_vm._v(_vm._s(_vm.$t("navbar.and"))+" "+_vm._s(_vm.count - 1)+" "+_vm._s(_vm.$t("navbar.more_days")))]):_vm._e(),_vm._v(". "+_vm._s(_vm.$t("navbar.check_order"))+" "),_c('router-link',{attrs:{"to":"/orders"}},[_vm._v(_vm._s(_vm.$t("navbar.orders")))])],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler navbar-toggler-right border-0",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbar4"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"d-inline-block mr-2 icon-width"},[_c('i',{staticClass:"fas fa-sign-out-alt"})])
}]

export { render, staticRenderFns }