import { render, staticRenderFns } from "./products.html?vue&type=template&id=dc879700&scoped=true&"
import script from "./products.js?vue&type=script&lang=js&"
export * from "./products.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc879700",
  null
  
)

export default component.exports